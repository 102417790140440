var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modalShowTransaction",
        lazy: "",
        "no-fade": "",
        "no-enforce-focus": "",
        "hide-footer": "",
        size: "xl",
        title: _vm.modal.title,
        "cancel-title": _vm.$t("payments.buttons.close"),
        "body-class": "pt-0 pb-0",
      },
      on: { show: _vm.handleOpen, hidden: _vm.handleClose },
      model: {
        value: _vm.cpHandlerModal,
        callback: function ($$v) {
          _vm.cpHandlerModal = $$v
        },
        expression: "cpHandlerModal",
      },
    },
    [
      _vm.currentRouter === _vm.router.default
        ? _c("b-container", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col-9",
                  style: [
                    { "padding-top": "32px" },
                    _vm.modal_data.proof
                      ? { "padding-bottom": "48px" }
                      : { "padding-bottom": "32px" },
                  ],
                },
                [
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        variant: "info",
                        show: _vm.data.status === "PAID",
                      },
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.$t("payments.labels.paymentMadeIn")) +
                            ": " +
                            _vm._s(
                              _vm._f("moment")(
                                _vm.data.paid_at,
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            ) +
                            " invoice: " +
                            _vm._s(_vm.data.invoice)
                        ),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "row lines" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("payments.labels.transactionCategory")
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "texts kind" }, [
                        _vm._v(" " + _vm._s(_vm.getTransactionKind) + " "),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("p", { staticClass: "titles font-weight-bold" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("payments.labels.gatewayUsed")) +
                              " "
                          ),
                        ]),
                        _c(
                          "b-badge",
                          {
                            staticStyle: { "font-size": "14px" },
                            attrs: { pill: "", variant: "secondary" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.setGateway(_vm.modal_data.gateway)) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-4" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.invoiceNumber")) +
                            " "
                        ),
                      ]),
                      _c(
                        "p",
                        { staticClass: "texts", attrs: { id: "clip-invoice" } },
                        [
                          _vm._v(" " + _vm._s(_vm.data.invoice) + " "),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "link", size: "sm" },
                              on: { click: _vm.copyInvoice },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-clipboard",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row lines" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.relatedRent")) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "texts" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.plan")) +
                            ": " +
                            _vm._s(_vm.data.booking_name) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "texts" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.licensePlate")) +
                            ": " +
                            _vm._s(_vm.data.car_plate) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-4" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.periodValidity")) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "texts" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.start")) +
                            ": " +
                            _vm._s(_vm.startPeriodValidity) +
                            " "
                        ),
                      ]),
                      _vm.finishedAtIsVisible
                        ? _c("p", { staticClass: "texts" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("payments.labels.end")) +
                                ": " +
                                _vm._s(_vm.endPeriodValidity) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "col-4" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.paymentMethod")) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "texts" }, [
                        _vm._v(" " + _vm._s(_vm.getPaymentMethod()) + " "),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row lines" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.originalValue")) +
                            " "
                        ),
                      ]),
                      _vm.showAmount
                        ? _c("p", { staticClass: "texts" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setPriceFormat(
                                    _vm.$t("payments.labels.currency"),
                                    _vm.data.amount_reference
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "col-4" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("payments.labels.discount")) + " "
                        ),
                      ]),
                      _vm.showAmount
                        ? _c("p", { staticClass: "texts" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setPriceFormat(
                                    _vm.$t("payments.labels.currency"),
                                    _vm.data.applied_discounts
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "col-4" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("payments.labels.amountWihDiscount")
                            ) +
                            " "
                        ),
                      ]),
                      _vm.showAmount
                        ? _c("p", { staticClass: "texts" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setPriceFormat(
                                    _vm.$t("payments.labels.currency"),
                                    _vm.data.amount_reference -
                                      _vm.data.applied_discounts
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "row lines" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _vm.showAmount
                            ? _c("p", { staticClass: "texts" }, [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$t("payments.labels.daysLate")}:`
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(`${_vm.data.days_late.toString()}`) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.showAmount
                            ? _c("p", { staticClass: "texts" }, [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$t("payments.labels.fine")}:`
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.setPriceFormat(
                                        _vm.$t("payments.labels.currency"),
                                        _vm.data.fine
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.showAmount
                            ? _c("p", { staticClass: "texts" }, [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      `${_vm.$t("payments.labels.interest")}:`
                                    )
                                  ),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `${_vm.setPriceFormat(
                                        _vm.$t("payments.labels.currency"),
                                        _vm.data.interest
                                      )}`
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.showAmount &&
                          _vm.data.payment_method_type_updated ===
                            "credit_card" &&
                          _vm.isBrazil
                            ? _c("p", { staticClass: "texts" }, [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("payments.labels.transactionFee")
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.setPriceFormat(
                                        _vm.$t("payments.labels.currency"),
                                        _vm.data.transaction_fee
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.showAmount
                            ? _c("p", { staticClass: "texts" }, [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      `${_vm.$t("payments.labels.taxesTotal")}:`
                                    )
                                  ),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `${_vm.setPriceFormat(
                                        _vm.$t("payments.labels.currency"),
                                        _vm.data.taxes_total
                                      )}`
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-4" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.amountPaid")) +
                            " "
                        ),
                      ]),
                      _vm.showAmount
                        ? _c("p", { staticClass: "texts pb-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setPriceFormat(
                                    _vm.$t("payments.labels.currency"),
                                    _vm.data.amount_paid
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.modal_data.wallet_adjustment_discount > 0
                        ? _c("p", { staticClass: "titles font-weight-bold" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "payments.labels.walletAdjustmentDiscount"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.modal_data.wallet_adjustment_discount > 0
                        ? _c("p", { staticClass: "texts" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setPriceFormat(
                                    _vm.$t("payments.labels.currency"),
                                    _vm.data.wallet_adjustment_discount
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "col-4" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.driverAmount")) +
                            " "
                        ),
                      ]),
                      _vm.showAmount
                        ? _c("p", { staticClass: "texts" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setPriceFormat(
                                    _vm.$t("payments.labels.currency"),
                                    _vm.data.amount
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "row lines" }, [
                    _vm.data.payment_method_type_updated === "credit_card"
                      ? _c("div", { staticClass: "col-4" }, [
                          _c("p", { staticClass: "titles font-weight-bold" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("payments.labels.installments")) +
                                " "
                            ),
                          ]),
                          _c("p", { staticClass: "texts" }, [
                            _vm._v(" " + _vm._s(_vm.data.installment) + " "),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "row lines" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.creationDate")) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "texts" }, [
                        _vm._v(" " + _vm._s(_vm.getCreatedAt) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-4" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.expireDateFull")) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "texts" }, [
                        _vm._v(" " + _vm._s(_vm.getOverdueLimit) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-4" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("payments.labels.tableFilterStatus")
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "texts" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("transaction_status")(_vm.data.status)
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm.isBrazil
                    ? _c("div", { staticClass: "row lines" }, [
                        _c("div", { staticClass: "col-8" }, [
                          _c("p", { staticClass: "titles font-weight-bold" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("payments.labels.methodAvailables")
                                ) +
                                " "
                            ),
                          ]),
                          _c("p", { staticClass: "texts" }, [
                            _vm._v(" " + _vm._s(_vm.getMethodAvailables) + " "),
                          ]),
                        ]),
                        _vm.isBrazil && _vm.data.contestation
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "col-4",
                                attrs: {
                                  title: _vm.getContestationTitle(
                                    _vm.data.contestation
                                  ),
                                },
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "titles font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("payments.labels.contestation")
                                        ) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "fa fa-info-circle ml-1",
                                    }),
                                  ]
                                ),
                                _c("p", { staticClass: "texts" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          `payments.enumStatusContestation.${_vm.data.contestation.status}`
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "row lines" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("p", { staticClass: "titles font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("payments.labels.paymentDescription")
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "texts" }, [
                        _vm._v(" " + _vm._s(_vm.data.description) + " "),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      class: { lines: _vm.modal_data.proof },
                    },
                    [
                      _c("div", { staticClass: "col-12" }, [
                        _c("p", { staticClass: "titles font-weight-bold" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("payments.labels.paymentComments")
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "comments" }, [
                          _vm._v(
                            " " + _vm._s(_vm.data.internal_comments) + " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      class: { lines: _vm.modal_data.proof },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12 pt-4 text-center" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "primary" },
                              on: { click: _vm.getTransactionEvents },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-history",
                                class: _vm.loadHistoryTransaction
                                  ? "fa-spin"
                                  : "",
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("payments.buttons.viewHistory")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "b-collapse",
                            {
                              staticClass: "mt-2",
                              model: {
                                value: _vm.showTimeLineEvents,
                                callback: function ($$v) {
                                  _vm.showTimeLineEvents = $$v
                                },
                                expression: "showTimeLineEvents",
                              },
                            },
                            [
                              _c(
                                "b-card",
                                [
                                  _c("driver-timeline-transaction", {
                                    attrs: { events: _vm.transactionEvents },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.modal_data.proof
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c(
                              "p",
                              { staticClass: "titles font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("payments.labels.receipts")) +
                                    " "
                                ),
                              ]
                            ),
                            _c("proof-of-payment", {
                              attrs: { modal_data: _vm.modal_data },
                              on: {
                                approved: function ($event) {
                                  return _vm.showModal(
                                    "successProofPaymentApproved"
                                  )
                                },
                                rejected: function ($event) {
                                  return _vm.showModal(
                                    "successProofPaymentRejected"
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-3 ccc",
                  style: [
                    {
                      "border-left": "1px solid #EAEDF3",
                      "padding-top": "39px",
                    },
                    _vm.modal_data.proof
                      ? { "padding-bottom": "48px" }
                      : { "padding-bottom": "32px" },
                  ],
                },
                [
                  _vm.currentRouter === _vm.router.default
                    ? _c("footer-status-pending", {
                        attrs: {
                          slot: "modal-footer",
                          data: _vm.data,
                          modal: _vm.modal,
                          block_ui: _vm.block_ui,
                        },
                        on: {
                          close: _vm.handleClose,
                          refundPayment: function ($event) {
                            return _vm.openModalName("showRefundConfirm")
                          },
                          reIssueBoleto: function ($event) {
                            return _vm.openModalName("reIssueBoleto")
                          },
                          cancelTransaction: function ($event) {
                            return _vm.openModalName("cancelTransaction")
                          },
                          openModalDebitBalance: function ($event) {
                            return _vm.openModalName("debitBalance")
                          },
                          openModalCreditBalance: function ($event) {
                            return _vm.openModalName("creditBalance")
                          },
                          openModalsendInvoice: function ($event) {
                            return _vm.openModalName("sendInvoice")
                          },
                          openModalOpenContestation: function ($event) {
                            return _vm.openModalName("openContestation")
                          },
                          openModalCloseContestation: function ($event) {
                            return _vm.openModalName("closeContestation")
                          },
                          markAsBonus: function ($event) {
                            return _vm.openModalName("markAsBonus")
                          },
                          checkPaymentAttempt: _vm.checkPaymentAttempt,
                          convertPrePaymentInCredits:
                            _vm.convertPrePaymentInCredits,
                          payWithPrePayment: _vm.payWithPrePayment,
                          submit: function ($event) {
                            return _vm.openModalName("handleSubmit")
                          },
                        },
                        slot: "modal-footer",
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.data.status === "PAID"
        ? _c("modal-refunded-confirm", {
            attrs: {
              "modal-data": _vm.data,
              "methods-payment": _vm.getPaymentMethod(),
            },
            on: {
              closeRefund: function ($event) {
                return _vm.closeModalName("refund")
              },
              succesRefund: function ($event) {
                return _vm.showModal("successDefault")
              },
              errorRefund: function ($event) {
                return _vm.showModal("errorDefault")
              },
            },
            model: {
              value: _vm.modalRefund,
              callback: function ($$v) {
                _vm.modalRefund = $$v
              },
              expression: "modalRefund",
            },
          })
        : _vm._e(),
      _c("modal-debit-balance", {
        attrs: { "modal-data": _vm.data },
        on: {
          closeModalDebitBalance: function ($event) {
            return _vm.closeModalName("debitBalance")
          },
          successUseCredits: function ($event) {
            return _vm.showModal("successDefault")
          },
          errorDebitCredits: function ($event) {
            return _vm.showModal("errorDefault")
          },
        },
        model: {
          value: _vm.useDebittModal,
          callback: function ($$v) {
            _vm.useDebittModal = $$v
          },
          expression: "useDebittModal",
        },
      }),
      _c("modal-credit-balance", {
        attrs: { "modal-data": _vm.data },
        on: {
          updateAmountPaid: _vm.updateAmountPaid,
          closeModalCreditBalance: function ($event) {
            return _vm.closeModalName("creditBalance")
          },
          successCreditBalance: function ($event) {
            return _vm.showModal("successDefault")
          },
          errorCreditBalance: function ($event) {
            return _vm.showModal("errorDefault")
          },
        },
        model: {
          value: _vm.useCreditModal,
          callback: function ($$v) {
            _vm.useCreditModal = $$v
          },
          expression: "useCreditModal",
        },
      }),
      _c("modal-cancel-transaction", {
        attrs: { "modal-data": _vm.data },
        on: {
          closeModalCancelTransaction: function ($event) {
            return _vm.closeModalName("cancelTransaction")
          },
          successCancelTransaction: function ($event) {
            return _vm.showModal("successDefault")
          },
          errorCancelTransaction: function ($event) {
            return _vm.showModal("errorDefault")
          },
        },
        model: {
          value: _vm.useCancelTransactionModal,
          callback: function ($$v) {
            _vm.useCancelTransactionModal = $$v
          },
          expression: "useCancelTransactionModal",
        },
      }),
      _c("modal-send-invoice", {
        attrs: { "modal-data": _vm.data },
        on: {
          closeModalSendInvoice: function ($event) {
            return _vm.closeModalName("sendInvoice")
          },
          successSendInvoice: function ($event) {
            return _vm.showModal("successSendInvoice")
          },
          errorSendInvoice: function ($event) {
            return _vm.showModal("errorSendInvoice")
          },
        },
        model: {
          value: _vm.useSendInvoiceModal,
          callback: function ($$v) {
            _vm.useSendInvoiceModal = $$v
          },
          expression: "useSendInvoiceModal",
        },
      }),
      _c("modal-open-contestation", {
        attrs: { transaction: _vm.data },
        on: {
          closeModalOpenContestation: function ($event) {
            return _vm.closeModalName("openContestation")
          },
          successOpenContestation: function ($event) {
            return _vm.showModal("successDefault")
          },
          errorOpenContestation: function ($event) {
            return _vm.showModal("errorDefault")
          },
        },
        model: {
          value: _vm.useOpenContestation,
          callback: function ($$v) {
            _vm.useOpenContestation = $$v
          },
          expression: "useOpenContestation",
        },
      }),
      _c("modal-close-contestation", {
        attrs: {
          driverId: _vm.data.driver,
          transactionId: _vm.data.id,
          contestation: _vm.data.contestation,
        },
        on: {
          closeModalCloseContestation: function ($event) {
            return _vm.closeModalName("closeContestation")
          },
          successCloseContestation: function ($event) {
            return _vm.showModal("successDefault")
          },
          errorCloseContestation: function ($event) {
            return _vm.showModal("errorDefault")
          },
        },
        model: {
          value: _vm.useCloseContestation,
          callback: function ($$v) {
            _vm.useCloseContestation = $$v
          },
          expression: "useCloseContestation",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }