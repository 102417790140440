<template>
  <!-- Add Transaction Modal -->
  <b-modal
    id="modalNewTransaction"
    v-model="cpHandlerModal"
    lazy
    no-fade
    no-enforce-focus
    hide-footer
    size="lg"
    :title="modal.title"
    :cancel-title="$t('payments.buttons.close')"
    @show="handleOpen"
    @hidden="handleClose"
  >
    <b-container v-if="currentRouter === router.default">
      <div class="row">
        <div class="col-12">
          <b-form validate>
            <b-form-row class="lines">
              <b-form-group
                class="col-md-12 col-sm-12 mb-0"
                label-class="font-weight-bold"
                :label="$t('payments.labels.transactionCategory')"
              >
                <multiselect
                  label="text"
                  :placeholder="$t('payments.labels.transactionCategory')"
                  track-by="value"
                  :close-on-select="true"
                  :disabled="!form_select_transaction_kind.length"
                  :multiple="false"
                  :options="form_select_transaction_kind"
                  :value="getSelected"
                  @input="setSelected"
                />
                <b-form-invalid-feedback
                  :state="checkField(data.kind)"
                  v-html="$t('payments.labels.mandatoryField')"
                />
              </b-form-group>
            </b-form-row>

            <b-form-row class="lines">
              <b-form-group class="col-md-12 mb-0" label-class="font-weight-bold" :label="$t('payments.labels.relatedRent')">
                <div v-if="data.id">
                  <div class="mt-1">
                    <strong>{{ data.booking_name }}</strong>
                  </div>
                </div>
                <div v-else>
                  <b-form-select
                    id="transaction-payment-method"
                    v-model="data.booking"
                    :state="checkField(data.type)"
                    :disabled="block_ui"
                    :options="form_bookings_methods"
                    @change="setKindConfigurations"
                  />
                  <b-form-invalid-feedback v-html="$t('payments.labels.mandatoryField')" />
                </div>
              </b-form-group>
            </b-form-row>

            <b-form-row class="lines">
              <b-form-group
                :label="$t('payments.labels.paymentMethod')"
                label-class="font-weight-bold"
                label-for="transaction-payment-method"
                class="col-md-6 col-sm-12 mb-0"
              >
                <b-form-select
                  id="transaction-payment-method"
                  v-model="setPaymentMethod"
                  :disabled="block_ui"
                  :state="checkField(data.payment_method)"
                  :options="form_payment_methods"
                />
                <b-form-invalid-feedback v-html="$t('payments.labels.mandatoryField')" />
              </b-form-group>
            </b-form-row>

            <b-form-row class="lines">
              <mask-input
                v-model="data.amount"
                :mask-label="$t('payments.labels.value')"
                class="font-weight-bold"
                :prepend="$t('payments.labels.currency')"
                parent-class="col-md-6 mb-0"
                :disabled="block_ui"
                :mask="['####,##', '###,##', '##,##', '#,##']"
                :field-state="checkField(data.amount)"
                @request="getInterest"
              />

              <b-form-group
                class="col-md-6 col-sm-12 mb-0 fix-padding"
                label-class="font-weight-bold"
                :label="$t('payments.labels.expireDateFull')"
                label-for="transaction-expire"
              >
                <date-picker
                  id="transaction-expire"
                  v-model="getExpiredAt"
                  :start-at="this.$moment().toDate().toString()"
                  :not-before="minimunDate"
                  :not-after="setmaxDaysAfter"
                  :hide="block_ui || isCreditCard"
                />
              </b-form-group>
            </b-form-row>

            <b-form-row v-if="show_installments" class="lines">
              <b-form-group
                :label="$t('payments.labels.installments')"
                label-class="font-weight-bold"
                label-for="transaction-payment-installments"
                class="col-md-4 col-sm-12"
              >
                <b-form-select
                  id="transaction-payment-installments"
                  v-model="data.installment"
                  :disabled="!isCreditCard || !data.amount || block_ui"
                  :options="form_installments"
                />
              </b-form-group>
              <span v-if="isBrazil" class="col-md-12 col-sm-12 mb-0" :style="{color: 'red'}">
                {{ $t('payments.labels.transactionFeeInformation') }}
              </span>
            </b-form-row>

            <b-form-row class="lines">
              <b-form-group
                :label="$t('payments.labels.paymentDescription')"
                label-class="font-weight-bold"
                label-for="transaction-description"
                class="col-md-12 col-sm-12 mb-0"
              >
                <b-form-input
                  id="transaction-description"
                  v-model="data.description"
                  :placeholder="$t('payments.labels.transactionReason')"
                  :disabled="block_ui"
                  type="text"
                />
              </b-form-group>
            </b-form-row>

            <b-form-row class="lines">
              <b-form-group
                :label="$t('payments.labels.paymentComments')"
                label-class="font-weight-bold"
                label-for="transaction-internal-comments"
                class="col-md-12 col-sm-12 mb-0"
              >
                <b-form-textarea
                  id="transaction-internal-comments"
                  v-model="data.internal_comments"
                  :disabled="block_ui"
                  type="text"
                  :rows="1"
                  :max-rows="4"
                />
              </b-form-group>
            </b-form-row>
          </b-form>

          <div class="row last-line">
            <div class="col-4 offset-4">
              <b-button
                data-test="modal-transaction__btn-close"
                class="relative"
                block
                @click="handleClose"
              >
                {{ modal.close_button }}
              </b-button>
            </div>

            <div class="col-4">
              <b-button
                :disabled="block_ui"
                variant="success"
                data-test="modal-transaction__btn-save"
                block
                @click="handleSubmit"
              >
                {{ modal.save_button }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { makeQuery } from '@graphql/middleware'
import find from 'lodash/find';
import { enumType } from '@utils/wallet';
import {transactionKind, transactionKindEnum} from '@utils/transaction';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import { VMoney } from 'v-money';

import TRANSACTION_CREATE from '@graphql/transaction/mutations/create.gql';
import TRANSACTION_UPDATE from '@graphql/transaction/mutations/update.gql';
import DRIVER_PAYMENT_METHODS from '@graphql/payment/queries/get.gql';
import DRIVER_BOOKINGS from '@graphql/booking/queries/driver.gql';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalTransaction',
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  mounted() {
    this.getBookings()
  },
  mixins: [validationMixin],
  props: {
    modal_data: {
      type: Object,
      required: true,
    },
    handlerModal: {
      type: Boolean,
    },
  },
  validations: {
    data: {
      amount_paid: {
        required,
        maxLength: maxLength(8),
      },
    },
  },
  directives: {
    money: VMoney,
  },
  data() {
    return {
      isCreditCard: null,
      isSubscriptionTermination: null,
      btnCreateClicked: false,
      modalController: false,
      currentRouter: 0,
      router: {
        default: 0,
        upload: 1,
        updateLoading: 2,
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      data: {
        kovi_fatura: process.env.KOVI_FATURA_URL,
        id: null,
        driver: null,
        booking: null,
        invoice: null,
        booking_name: null,
        payment_method: null,
        payment_method_token: null,
        payment_method_type: null,
        amount: 0,
        amount_paid: 0,
        amount_reference: 0,
        discount: 0,
        installment: '',
        type: 'DEBIT',
        kind: null,
        status: null,
        internal_comments: null,
        description: null,
        updated_at: null,
        expired_at: null,
      },
      modal: {
        title: this.$t('payments.labels.newInvoice'),
        save_button: this.$t('payments.buttons.createInvoice'),
        save_success_title: this.$t('payments.labels.saveSuccess'),
        close_button: this.$t('payments.buttons.close')
      },
      block_ui: false,
      block_ui_update: false,
      form_installments: [],
      payment_methods: [],
      form_payment_methods: [],
      form_bookings_methods: [],
      form_select_transaction_type: enumType,
      form_select_transaction_kind: [],
      show_installments: false,
      kindConfiguration: {}
    };
  },
  computed: {
    ...mapGetters('user', ['findGroups']),
    setPaymentMethod: {
      get() {
        return this.data.payment_method;
      },
      set(value) {
        let isCreditCard = false;

        this.form_payment_methods.map(item => {
          item.value === value && (item.text.substr(0, 5).includes('CART') || item.text.includes('TARJETA')) ? (isCreditCard = true) : '';
        });

        this.isCreditCard = !!isCreditCard;

        this.data.payment_method = value;

        if (!this.isCreditCard) {
          this.show_installments = false;
          return (this.form_installments = []);
        }

        this.show_installments = true;

        return this.getInterest();
      },
    },
    setKind: {
      get() {
        return this.data.kind;
      },
      set(value) {
        this.data.kind = value;
        this.form_installments = [];
        return this.getInterest();
      },
    },
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      },
    },
    getExpiredAt: {
      get() {
        if(this.isCreditCard){
          return this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
        }
        let utc = this.$moment.utc(this.data.expired_at);
        return this.$moment.tz(utc, this.data.timezone).format("YYYY-MM-DD HH:mm:ss");
      },
      set(value) {
        this.data.expired_at = value;
      },
    },
    getPlanId() {
      if (!this.data.booking) return;
      const planData = this.form_bookings_methods.find(item => item.value === this.data.booking);
      return planData.plan_id || null;
    },
    setmaxDaysAfter: function () {
      return this.$moment().add(this.kindConfiguration.maxDays || 1, 'day').format('YYYY-MM-DD')
    },
    minimunDate() {
      return this.$moment().toDate();
    },
    getSelected () {
      const selected = this.data.kind
      const available = this.form_select_transaction_kind

      return selected && available.length
        ? available.find(item => item.value === selected)
        : null;
    },
    isBrazil() {
      return (this.$store.getters['user/country'] === 'BR');
    },
  },
  apollo: {
    payment_method: {
      query: DRIVER_PAYMENT_METHODS,
      variables() {
        return {
          driver: this.$route.params.id,
        };
      },
      update: data => data.paymentMethods,
      result(data) {
        if (!data.loading) {
          let _items = [];
          let _this = this;
          if (!data.data.paymentMethods.items.length) {
            data.data.paymentMethods.items.push({ id: 1, type: 'boleto', token:"token", driver: { name: 'Novo' } });
          }
          data.data.paymentMethods.items.forEach(item => {
            _items.push({
              value: item.id,
              type: item.type,
              text:
                item.type === 'credit_card'
                  ? `${_this.$t('payments.enumType.card').toUpperCase()}: ${item.cc_holder_name} (${item.cc_brand.toUpperCase()}: ${item.cc_number})`
                  : `${_this.getPaymentMethodType(item.type)}: ${item.driver.name}`,
            });
          });
          this.payment_methods = _items;
          this.availablePaymentMethods = data.data.getAvailablePaymentMethods;
        }
      },
    },
  },
  methods: {
    handleOpen() {
      this.block_ui = false;
      this.block_ui_update = false;
      this.data.timezone = this.modal_data.timezone

      this.data.id = null;
      this.data.driver = this.modal_data.driver;
      this.data.booking = this.getPlanActive();
      this.data.invoice = null;
      this.data.booking_name = null;
      this.data.payment_method = null;
      this.data.payment_method_token = null;
      this.data.payment_method_type = null;
      this.data.barcode_number = null;
      this.data.description = '';
      this.data.amount = null;
      this.data.installment = null;
      this.data.amount_paid = null;
      this.data.amount_reference = null;
      this.data.discount = 0;
      this.data.type = 'DEBIT';
      this.data.kind = null;
      this.data.status = null;
      this.data.internal_comments = null;
      this.data.expired_at = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss');
      this.data.updated_at = null;
      this.btnCreateClicked = false;

      this.form_select_transaction_kind = transactionKind.filter(kind => kind.value !== transactionKindEnum.NEGOTIATION
        && kind.value !== transactionKindEnum.INSTALLMENT
        && kind.value !== transactionKindEnum.FIRST_PAYMENT_EXCHANGE);
      if (!this.findGroups('ACL:TRANSACTION:SUBSCRIPTION_TERMINATION')) {
        this.form_select_transaction_kind = this.form_select_transaction_kind.filter(kind => kind.value !== transactionKindEnum.SUBSCRIPTION_TERMINATION);
      }
    },
    handleSubmit() {
      this.doCreateTransaction();
    },
    handleClose() {
      this.block_ui = false;
      this.block_ui_update = false;
      this.currentRouter = this.router.default;
      this.$emit('change', false);
    },
    checkField(value) {
      return this.btnCreateClicked ? !!value : null;
    },
    getPaymentMethodType (type) {
      switch (type) {
        case 'boleto':
          return this.$t('payments.enumType.billet').toUpperCase()
        case 'credit_card':
          return this.$t('payments.enumType.card').toUpperCase()
        case 'pix':
          return this.$t('payments.enumType.pix').toUpperCase()
        case 'oxxo':
          return this.$t('payments.enumType.oxxo').toUpperCase()
        default:
          return ' - '
      }
    },
    async getBookings () {
      await makeQuery(
        DRIVER_BOOKINGS,
        { id: this.$route.params.id }
      )
      .then((data) => {
        if (data.loading) return;
        let _items = [];
        data.data.bookingsByDriver.items.forEach(item => {
          if (item.status === 'CANCELED') return;

          const textHasFinished = item.finished_at ? `${this.$moment(item.started_at).format('DD/MM/YYYY')} a ${this.$moment(item.finished_at).format('DD/MM/YYYY')}` : 'Ativo';

          const carPlate = item.car?.license_plate ? `${this.$t(`payments.labels.licensePlate`)}: ${item.car.license_plate}` : ''

          if (textHasFinished === 'Ativo') this.data.booking = item.id;

          _items.push({
            value: item.id,
            plan_id: item.plan.id,
            text: `${item.plan.name} - ${carPlate} - ${textHasFinished}`,
            kind_configurations: item.plan.kind_configurations,
            status: item.status
          });
        });

        this.form_bookings_methods = _items;
      })
    },
    async getInterest() {
      if (!this.data.kind || !this.data.booking || !this.isCreditCard || !parseInt(this.data.amount)) return [];
      this.block_ui = true;
      this.$swal.fire({
        title: this.$t(`payments.labels.waitInstallments`),
        onBeforeOpen: () => this.$swal.showLoading(),
      });
      
      this.form_installments = [];
      const installments = this.kindConfiguration?.installments || 0;
      for (var index = 1; index <= installments; index++) {
        const installment = index;
        const installmentAmount = (this.data.amount / installment);
        this.form_installments.push({
        value: installment,
        text: `${installment}x de ${(installmentAmount/100).toFixed(2)} | Total: ${(this.data.amount/100).toFixed(2)}`,
      });
      }
      
      this.data.installment = 1;
      this.$swal.close();
      this.block_ui = false;
    },
    setTransactionDescription(kind) {
      if (!kind) return;
      this.getInterest();
      const kindData = find(transactionKind, {
        value: kind,
      });
      this.data.description = kindData.text + ' ';
      this.setKindConfigurations()
    },
    getPlanActive() {
      if (this.form_bookings_methods.length > 0) {
        const planData = this.form_bookings_methods.find(item => item.status === "ACTIVE");

        return planData ? planData.value : ''
      }

      return ''
    },
    showModal (type) {
      let showModalObject = {
        type: 'success',
        timer: 2500,
        showConfirmButton: false
      }

      if (type === 'errorDefault') showModalObject.type = 'error'

      switch (type) {
        case 'errorDefault':
          showModalObject.title = this.$t('modal.popup.error.title')
          showModalObject.text = this.$t('modal.popup.error.text')
          break
        default:
          showModalObject.title = this.modal.save_success_title
          break
      }

      this.block_ui = false;

      if (type === 'errorDefault') {
        this.$swal(showModalObject)
          .then(() => this.$emit('refresh'))
      } else {
        this.$emit('change', false);

        this.$swal(showModalObject)
          .then(() => this.$emit('refresh'))
      }
    },
    setAvailablePaymentMethods(value) {
      const availablePaymentMethods = new Set(
        this.availablePaymentMethods
          .filter(item => item.identifier === value)
          .map(item => item.value)
      );
      this.form_payment_methods = (availablePaymentMethods.size === 0 || !this.isBrazil)
      ? this.payment_methods 
      : this.payment_methods.filter(method => availablePaymentMethods.has(method.type));
    },
    doCreateTransaction() {
      const original_data = this.data;
      this.btnCreateClicked = true;

      // Do validations
      if (!this.data.amount || !this.data.kind || !this.data.booking || !this.data.payment_method || (this.isCreditCard && !this.data.installment)) return;

      this.block_ui = true;

      this.$apollo
        .mutate({
          mutation: TRANSACTION_CREATE,
          variables: {
            ...this.data,
            expired_at: this.$moment(this.data.expired_at)
              .utc()
              .format('YYYY-MM-DD HH:mm:ss'),
            payment_method_token: this.data.payment_method_token
              ? this.data.payment_method_token
              : find(this.payment_method.items, {
                  id: this.data.payment_method,
                }).token || "token",
            payment_method_type: this.data.payment_method_type
              ? this.data.payment_method_type
              : find(this.payment_method.items, {
                  id: this.data.payment_method,
                }).type,
            amount: parseInt(this.data.amount),
            discount: parseInt(this.data.discount),
            installment: parseInt(this.data.installment),
            interest_input: {
              plan: this.getPlanId,
              amountReference: parseInt(this.data.amount, 10),
              transactionKind: this.data.kind,
              installments: this.data.installment,
            },
          },
        })
        .then(() => {
          this.$emit('change', false);
          this.showModal('successDefault');
        })
        .catch(() => {
          this.data = original_data;
          this.showModal('errorDefault');
        });
    },
    // Sem definicao ainda de onde foi parar a atualziacao, entao mantendo o codigo para futuros updates
    doUpdateTransaction() {
      const original_data = this.data;
      this.block_ui = true;
      this.block_ui_update = false;
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });
      confirmationWindow({
        title: 'Tem certeza?',
        text: 'Você irá mudar a cobrança deste motorista',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then(result => {
        if (!result.value) return (this.block_ui = false);
        this.$apollo
          .mutate({
            mutation: TRANSACTION_UPDATE,
            variables: {
              id: this.data.id,
              driver: this.data.driver,
              booking: this.data.booking,
              invoice: this.data.invoice,
              payment_method: this.data.payment_method,
              internal_comments: this.data.internal_comments,
              type: this.data.type,
              kind: this.data.kind,
              description: this.data.description,
              payment_method_token: this.data.payment_method_token
                ? this.data.payment_method_token
                : find(this.payment_method.items, {
                    id: this.data.payment_method,
                  }).token,
              payment_method_type: this.data.payment_method_type
                ? this.data.payment_method_type
                : find(this.payment_method.items, {
                    id: this.data.payment_method,
                  }).type,
              amount: parseInt(this.data.amount),
              discount: parseInt(this.data.discount),
              installment: parseInt(this.data.installment) || 1,
            },
          })
          .then(() => this.showModal('successDefault'))
          .catch(() => {
            this.data = original_data;
            this.showModal('errorDefault');
          });
      });
    },
    setKindConfigurations () {
      const kindSelected = this.form_bookings_methods.find(booking => booking.value === this.data.booking)
      if (!kindSelected) return
      this.kindConfiguration = kindSelected.kind_configurations[this.data.kind]
      this.data.expired_at = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
    },
    setSelected ({ value }) {
      this.data.kind = value;
      this.form_installments = [];
      this.getInterest();
      this.setAvailablePaymentMethods(value);
      this.setTransactionDescription(value);
    },
  }
};
</script>

<style scoped>
.lines {
  padding-bottom: 24px;
}
.last-line {
  padding-bottom: 6px;
}
.fix-padding {
  padding-top: 5px;
}
.titles {
  margin-bottom: 8px;
  font-size: 14px;
  color: #000000;
}
</style>
