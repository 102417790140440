var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modalNewTransaction",
        lazy: "",
        "no-fade": "",
        "no-enforce-focus": "",
        "hide-footer": "",
        size: "lg",
        title: _vm.modal.title,
        "cancel-title": _vm.$t("payments.buttons.close"),
      },
      on: { show: _vm.handleOpen, hidden: _vm.handleClose },
      model: {
        value: _vm.cpHandlerModal,
        callback: function ($$v) {
          _vm.cpHandlerModal = $$v
        },
        expression: "cpHandlerModal",
      },
    },
    [
      _vm.currentRouter === _vm.router.default
        ? _c("b-container", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "b-form",
                    { attrs: { validate: "" } },
                    [
                      _c(
                        "b-form-row",
                        { staticClass: "lines" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-12 col-sm-12 mb-0",
                              attrs: {
                                "label-class": "font-weight-bold",
                                label: _vm.$t(
                                  "payments.labels.transactionCategory"
                                ),
                              },
                            },
                            [
                              _c("multiselect", {
                                attrs: {
                                  label: "text",
                                  placeholder: _vm.$t(
                                    "payments.labels.transactionCategory"
                                  ),
                                  "track-by": "value",
                                  "close-on-select": true,
                                  disabled:
                                    !_vm.form_select_transaction_kind.length,
                                  multiple: false,
                                  options: _vm.form_select_transaction_kind,
                                  value: _vm.getSelected,
                                },
                                on: { input: _vm.setSelected },
                              }),
                              _c("b-form-invalid-feedback", {
                                attrs: { state: _vm.checkField(_vm.data.kind) },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("payments.labels.mandatoryField")
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-form-row",
                        { staticClass: "lines" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-12 mb-0",
                              attrs: {
                                "label-class": "font-weight-bold",
                                label: _vm.$t("payments.labels.relatedRent"),
                              },
                            },
                            [
                              _vm.data.id
                                ? _c("div", [
                                    _c("div", { staticClass: "mt-1" }, [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.data.booking_name)),
                                      ]),
                                    ]),
                                  ])
                                : _c(
                                    "div",
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          id: "transaction-payment-method",
                                          state: _vm.checkField(_vm.data.type),
                                          disabled: _vm.block_ui,
                                          options: _vm.form_bookings_methods,
                                        },
                                        on: {
                                          change: _vm.setKindConfigurations,
                                        },
                                        model: {
                                          value: _vm.data.booking,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.data, "booking", $$v)
                                          },
                                          expression: "data.booking",
                                        },
                                      }),
                                      _c("b-form-invalid-feedback", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t(
                                              "payments.labels.mandatoryField"
                                            )
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-form-row",
                        { staticClass: "lines" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-6 col-sm-12 mb-0",
                              attrs: {
                                label: _vm.$t("payments.labels.paymentMethod"),
                                "label-class": "font-weight-bold",
                                "label-for": "transaction-payment-method",
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "transaction-payment-method",
                                  disabled: _vm.block_ui,
                                  state: _vm.checkField(
                                    _vm.data.payment_method
                                  ),
                                  options: _vm.form_payment_methods,
                                },
                                model: {
                                  value: _vm.setPaymentMethod,
                                  callback: function ($$v) {
                                    _vm.setPaymentMethod = $$v
                                  },
                                  expression: "setPaymentMethod",
                                },
                              }),
                              _c("b-form-invalid-feedback", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("payments.labels.mandatoryField")
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-form-row",
                        { staticClass: "lines" },
                        [
                          _c("mask-input", {
                            staticClass: "font-weight-bold",
                            attrs: {
                              "mask-label": _vm.$t("payments.labels.value"),
                              prepend: _vm.$t("payments.labels.currency"),
                              "parent-class": "col-md-6 mb-0",
                              disabled: _vm.block_ui,
                              mask: ["####,##", "###,##", "##,##", "#,##"],
                              "field-state": _vm.checkField(_vm.data.amount),
                            },
                            on: { request: _vm.getInterest },
                            model: {
                              value: _vm.data.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "amount", $$v)
                              },
                              expression: "data.amount",
                            },
                          }),
                          _c(
                            "b-form-group",
                            {
                              staticClass:
                                "col-md-6 col-sm-12 mb-0 fix-padding",
                              attrs: {
                                "label-class": "font-weight-bold",
                                label: _vm.$t("payments.labels.expireDateFull"),
                                "label-for": "transaction-expire",
                              },
                            },
                            [
                              _c("date-picker", {
                                attrs: {
                                  id: "transaction-expire",
                                  "start-at": this.$moment()
                                    .toDate()
                                    .toString(),
                                  "not-before": _vm.minimunDate,
                                  "not-after": _vm.setmaxDaysAfter,
                                  hide: _vm.block_ui || _vm.isCreditCard,
                                },
                                model: {
                                  value: _vm.getExpiredAt,
                                  callback: function ($$v) {
                                    _vm.getExpiredAt = $$v
                                  },
                                  expression: "getExpiredAt",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.show_installments
                        ? _c(
                            "b-form-row",
                            { staticClass: "lines" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-4 col-sm-12",
                                  attrs: {
                                    label: _vm.$t(
                                      "payments.labels.installments"
                                    ),
                                    "label-class": "font-weight-bold",
                                    "label-for":
                                      "transaction-payment-installments",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "transaction-payment-installments",
                                      disabled:
                                        !_vm.isCreditCard ||
                                        !_vm.data.amount ||
                                        _vm.block_ui,
                                      options: _vm.form_installments,
                                    },
                                    model: {
                                      value: _vm.data.installment,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "installment", $$v)
                                      },
                                      expression: "data.installment",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.isBrazil
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "col-md-12 col-sm-12 mb-0",
                                      style: { color: "red" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "payments.labels.transactionFeeInformation"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-form-row",
                        { staticClass: "lines" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-12 col-sm-12 mb-0",
                              attrs: {
                                label: _vm.$t(
                                  "payments.labels.paymentDescription"
                                ),
                                "label-class": "font-weight-bold",
                                "label-for": "transaction-description",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "transaction-description",
                                  placeholder: _vm.$t(
                                    "payments.labels.transactionReason"
                                  ),
                                  disabled: _vm.block_ui,
                                  type: "text",
                                },
                                model: {
                                  value: _vm.data.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "description", $$v)
                                  },
                                  expression: "data.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-form-row",
                        { staticClass: "lines" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-12 col-sm-12 mb-0",
                              attrs: {
                                label: _vm.$t(
                                  "payments.labels.paymentComments"
                                ),
                                "label-class": "font-weight-bold",
                                "label-for": "transaction-internal-comments",
                              },
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "transaction-internal-comments",
                                  disabled: _vm.block_ui,
                                  type: "text",
                                  rows: 1,
                                  "max-rows": 4,
                                },
                                model: {
                                  value: _vm.data.internal_comments,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "internal_comments", $$v)
                                  },
                                  expression: "data.internal_comments",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row last-line" }, [
                    _c(
                      "div",
                      { staticClass: "col-4 offset-4" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "relative",
                            attrs: {
                              "data-test": "modal-transaction__btn-close",
                              block: "",
                            },
                            on: { click: _vm.handleClose },
                          },
                          [_vm._v(" " + _vm._s(_vm.modal.close_button) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.block_ui,
                              variant: "success",
                              "data-test": "modal-transaction__btn-save",
                              block: "",
                            },
                            on: { click: _vm.handleSubmit },
                          },
                          [_vm._v(" " + _vm._s(_vm.modal.save_button) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }