<template>
  <b-modal
    id="modalShowTransaction"
    v-model="cpHandlerModal"
    lazy
    no-fade
    no-enforce-focus
    hide-footer
    size="xl"
    :title="modal.title"
    :cancel-title="$t('payments.buttons.close')"
    body-class="pt-0 pb-0"
    @show="handleOpen"
    @hidden="handleClose"
  >
    <b-container v-if="currentRouter === router.default">
      <div class="row">
        <div class="col-9" :style="[ { 'padding-top': '32px' }, modal_data.proof ? { 'padding-bottom': '48px' } : { 'padding-bottom': '32px' } ]">
          <b-alert variant="info" :show="data.status === 'PAID'">
            <strong>{{ $t('payments.labels.paymentMadeIn') }}: {{ data.paid_at | moment('DD/MM/YYYY HH:mm:ss') }} invoice: {{ data.invoice }}</strong>
          </b-alert>

          <div class="row lines">
            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.transactionCategory') }}
              </p>
              <p class="texts kind">
                {{ getTransactionKind }}
              </p>
            </div>

            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.gatewayUsed') }}
              </p>
              <b-badge pill variant="secondary" style="font-size: 14px;">
                {{ setGateway(modal_data.gateway) }}
              </b-badge>
            </div>

            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.invoiceNumber') }}
              </p>
              <p id="clip-invoice" class="texts">
                {{ data.invoice }}
                <b-button variant="link" size="sm" @click="copyInvoice">
                  <i class="fa fa-clipboard" aria-hidden="true" />
                </b-button>
              </p>
            </div>
          </div>

          <div class="row lines">
            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.relatedRent') }}
              </p>
              <p class="texts">
                {{ $t('payments.labels.plan') }}: {{ data.booking_name }}
              </p>
              <p class="texts">
                {{ $t('payments.labels.licensePlate') }}: {{ data.car_plate }}
              </p>
            </div>

            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.periodValidity') }}
              </p>
              <p class="texts">
                {{ $t('payments.labels.start') }}: {{ startPeriodValidity }}
              </p>
              <p v-if="finishedAtIsVisible" class="texts">
                {{ $t('payments.labels.end') }}: {{ endPeriodValidity }}
              </p>
            </div>

            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.paymentMethod') }}
              </p>
              <p class="texts">
                {{ getPaymentMethod() }}
              </p>
            </div>
          </div>

          <div class="row lines">
            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.originalValue') }}
              </p>
              <p v-if="showAmount" class="texts">
                {{ setPriceFormat($t('payments.labels.currency'), data.amount_reference) }}
              </p>
            </div>

            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.discount') }}
              </p>
              <p v-if="showAmount" class="texts">
                {{ setPriceFormat($t('payments.labels.currency'),data.applied_discounts) }}
              </p>
            </div>

            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.amountWihDiscount') }}
              </p>
              <p v-if="showAmount" class="texts">
                {{ setPriceFormat($t('payments.labels.currency'), data.amount_reference - data.applied_discounts) }}
              </p>
            </div>
          </div>

          <div class="row lines">
            <div class="col-4">
              <div class="row">
                <div class="col-12">
                  <p v-if="showAmount" class="texts">
                    <b> {{ `${$t('payments.labels.daysLate')}:` }} </b>
                    {{ `${data.days_late.toString()}` }}
                  </p>
                  <p v-if="showAmount" class="texts">
                    <b> {{ `${$t('payments.labels.fine')}:` }} </b>
                    {{ setPriceFormat($t('payments.labels.currency'), data.fine) }}
                  </p>
                  <p v-if="showAmount" class="texts">
                    <b>{{ `${$t('payments.labels.interest')}:` }}</b>
                    {{ `${setPriceFormat($t('payments.labels.currency'), data.interest)}` }}
                  </p>
                  <p v-if="showAmount && data.payment_method_type_updated === 'credit_card' && isBrazil" class="texts">
                    <b> {{ $t('payments.labels.transactionFee') }} </b>
                    {{ setPriceFormat($t('payments.labels.currency'), data.transaction_fee) }}
                  </p>
                  <p v-if="showAmount" class="texts">
                    <b>{{ `${$t('payments.labels.taxesTotal')}:` }}</b>
                    {{ `${setPriceFormat($t('payments.labels.currency'), data.taxes_total)}` }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.amountPaid') }}
              </p>
              <p v-if="showAmount" class="texts pb-2">
                {{ setPriceFormat($t('payments.labels.currency'), data.amount_paid) }}
              </p>
              <p v-if="modal_data.wallet_adjustment_discount > 0" class="titles font-weight-bold">
                {{ $t('payments.labels.walletAdjustmentDiscount') }}
              </p>
              <p v-if="modal_data.wallet_adjustment_discount > 0" class="texts">
                {{ setPriceFormat($t('payments.labels.currency'), data.wallet_adjustment_discount) }}
              </p>
            </div>
            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.driverAmount') }}
              </p>
              <p v-if="showAmount" class="texts">
                {{ setPriceFormat($t('payments.labels.currency'), data.amount) }}
              </p>
            </div>
          </div>

          <div class="row lines">
            <div v-if="data.payment_method_type_updated === 'credit_card'" class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.installments') }}
              </p>
              <p class="texts">
                {{ data.installment }}
              </p>
            </div>
          </div>

          <div class="row lines">
            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.creationDate') }}
              </p>
              <p class="texts">
                {{ getCreatedAt }}
              </p>
            </div>

            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.expireDateFull') }}
              </p>
              <p class="texts">
                {{ getOverdueLimit }}
              </p>
            </div>

            <div class="col-4">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.tableFilterStatus') }}
              </p>
              <p class="texts">
                {{ data.status | transaction_status }}
              </p>
            </div>
          </div>

          <div v-if="isBrazil" class="row lines">
            <div class="col-8">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.methodAvailables') }}
              </p>
              <p class="texts">
                {{ getMethodAvailables }}
              </p>
            </div>

            <span
              v-if="isBrazil && data.contestation"
              v-b-tooltip.hover
              class="col-4"
              :title="getContestationTitle(data.contestation)"
            >
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.contestation') }}
                <i class="fa fa-info-circle ml-1" />
              </p>
              <p class="texts">
                {{ $t(`payments.enumStatusContestation.${data.contestation.status}`) }}
              </p>
            </span>
          </div>

          <div class="row lines">
            <div class="col-12">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.paymentDescription') }}
              </p>
              <p class="texts">
                {{ data.description }}
              </p>
            </div>
          </div>

          <div class="row" :class="{ lines: modal_data.proof }">
            <div class="col-12">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.paymentComments') }}
              </p>
              <div class="comments">
                {{ data.internal_comments }}
              </div>
            </div>
          </div>

          <div class="row" :class="{ lines: modal_data.proof }">
            <div class="col-12 pt-4 text-center">
              <b-button
                size="sm"
                variant="primary"
                @click="getTransactionEvents"
              >
                <i
                  class="fa fa-history"
                  :class="loadHistoryTransaction ? 'fa-spin': ''"
                />
                {{ $t('payments.buttons.viewHistory') }}
              </b-button>
            </div>
            <div class="col-12">
              <b-collapse v-model="showTimeLineEvents" class="mt-2">
                <b-card>
                  <driver-timeline-transaction
                    :events="transactionEvents"
                  />
                </b-card>
              </b-collapse>
            </div>
          </div>

          <div v-if="modal_data.proof" class="row">
            <div class="col-12">
              <p class="titles font-weight-bold">
                {{ $t('payments.labels.receipts') }}
              </p>
              <proof-of-payment
                :modal_data="modal_data"
                @approved="showModal('successProofPaymentApproved')"
                @rejected="showModal('successProofPaymentRejected')"
              />
            </div>
          </div>
        </div>
        <div class="col-3 ccc" :style="[ { 'border-left': '1px solid #EAEDF3', 'padding-top': '39px' }, modal_data.proof ? { 'padding-bottom': '48px' } : { 'padding-bottom': '32px' } ]">
          <footer-status-pending
            v-if="currentRouter === router.default"
            slot="modal-footer"
            :data="data"
            :modal="modal"
            :block_ui="block_ui"
            @close="handleClose"
            @refundPayment="openModalName('showRefundConfirm')"
            @reIssueBoleto="openModalName('reIssueBoleto')"
            @cancelTransaction="openModalName('cancelTransaction')"
            @openModalDebitBalance="openModalName('debitBalance')"
            @openModalCreditBalance="openModalName('creditBalance')"
            @openModalsendInvoice="openModalName('sendInvoice')"
            @openModalOpenContestation="openModalName('openContestation')"
            @openModalCloseContestation="openModalName('closeContestation')"
            @markAsBonus="openModalName('markAsBonus')"
            @checkPaymentAttempt="checkPaymentAttempt"
            @convertPrePaymentInCredits="convertPrePaymentInCredits"
            @payWithPrePayment="payWithPrePayment"
            @submit="openModalName('handleSubmit')"
          />
        </div>
      </div>
    </b-container>

    <modal-refunded-confirm
      v-if="data.status === 'PAID'"
      v-model="modalRefund"
      :modal-data="data"
      :methods-payment="getPaymentMethod()"
      @closeRefund="closeModalName('refund')"
      @succesRefund="showModal('successDefault')"
      @errorRefund="showModal('errorDefault')"
    />
    <modal-debit-balance
      v-model="useDebittModal"
      :modal-data="data"
      @closeModalDebitBalance="closeModalName('debitBalance')"
      @successUseCredits="showModal('successDefault')"
      @errorDebitCredits="showModal('errorDefault')"
    />
    <modal-credit-balance
      v-model="useCreditModal"
      :modal-data="data"
      @updateAmountPaid="updateAmountPaid"
      @closeModalCreditBalance="closeModalName('creditBalance')"
      @successCreditBalance="showModal('successDefault')"
      @errorCreditBalance="showModal('errorDefault')"
    />
    <modal-cancel-transaction
      v-model="useCancelTransactionModal"
      :modal-data="data"
      @closeModalCancelTransaction="closeModalName('cancelTransaction')"
      @successCancelTransaction="showModal('successDefault')"
      @errorCancelTransaction="showModal('errorDefault')"
    />
    <modal-send-invoice
      v-model="useSendInvoiceModal"
      :modal-data="data"
      @closeModalSendInvoice="closeModalName('sendInvoice')"
      @successSendInvoice="showModal('successSendInvoice')"
      @errorSendInvoice="showModal('errorSendInvoice')"
    />
    <modal-open-contestation
      v-model="useOpenContestation"
      :transaction="data"
      @closeModalOpenContestation="closeModalName('openContestation')"
      @successOpenContestation="showModal('successDefault')"
      @errorOpenContestation="showModal('errorDefault')"
    />

    <modal-close-contestation
      v-model="useCloseContestation"
      :driverId="data.driver"
      :transactionId="data.id"
      :contestation="data.contestation"
      @closeModalCloseContestation="closeModalName('closeContestation')"
      @successCloseContestation="showModal('successDefault')"
      @errorCloseContestation="showModal('errorDefault')"
    />
  </b-modal>
</template>

<script>
import { makeQuery, makeMutation } from '@graphql/middleware'
import ClipboardJS from 'clipboard';

import find from 'lodash/find';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import { enumType, formatGateway } from '@utils/wallet';
import { transactionKind } from '@utils/transaction';
import { paymentMethodTranslate } from '@utils/payment-method';
import { formatPrice } from '@utils/numbers';
import { mapActions, mapGetters } from 'vuex'

import TRANSACTION_UPDATE from '@graphql/transaction/mutations/update.gql';
import CREDIT_BALANCE from '@graphql/payment/mutations/credit-balance.gql';
import DEBIT_BALANCE from '@graphql/payment/mutations/debit-balance.gql';
import CHECK_PAYMENT_ATTEMPT from '@graphql/transaction/mutations/checkPaymentAttempt.gql';
import MARK_AS_BONUS from '@graphql/transaction/mutations/markAsBonus.gql';
import DRIVER_BOOKINGS from '@graphql/booking/queries/driver.gql';
import REISSUE_BOLETO from '@graphql/payment/mutations/reissue.gql';
import GET_TRANSACTION_EVENTS from '@graphql/transaction/queries/transactionEvents.gql';

export default {
  name: 'ModalTransaction',
  components: {
    ProofOfPayment: () => import('./transaction/proof-of-payment'),
    FooterStatusPending: () => import('./transaction/footer-status-pending'),
    ModalRefundedConfirm: () => import('@components/modals/refundedConfirm'),
    ModalDebitBalance: () => import('@components/modals/wallet/debit-balance'),
    ModalCreditBalance: () => import('@components/modals/wallet/credit-balance'),
    ModalCancelTransaction: () => import('@components/modals/cancel-transaction'),
    ModalSendInvoice: () => import('@components/modals/send-invoice'),
    ModalOpenContestation: () => import('@components/modals/transaction/open-contestation'),
    ModalCloseContestation: () => import('@components/modals/transaction/close-contestation'),
    DriverTimelineTransaction: () => import('@components/drivers/payment-info/driver-timeline-transaction')
  },
  mixins: [validationMixin],
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  props: {
    modal_data: {
      type: Object,
      required: true,
    },
    handlerModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      btnCreateClicked: false,
      modalController: false,
      proofImage: null,
      currentRouter: 0,
      modalRefund: false,
      router: {
        default: 0,
        upload: 1,
        updateLoading: 2,
      },
      data: {
        kovi_fatura: process.env.KOVI_FATURA_URL,
        id: null,
        driver: null,
        booking: null,
        invoice: null,
        booking_name: null,
        payment_method: null,
        payment_method_token: null,
        payment_method_type: null,
        payment_method_type_updated: null,
        amount: 0,
        amount_paid: 0,
        amount_reference: 0,
        discount: 0,
        installment: '',
        type: 'DEBIT',
        kind: null,
        status: null,
        internal_comments: null,
        description: null,
        paid_at: null,
        overdue_limit: null,
        create_at: null,
        applied_discounts: 0,
        wallet_adjustment_discount: 0,
        method_available: [],
      },
      modal: {
        title: '',
        save_button: '',
        save_success_title: '',
        cancel_button: this.$t('payments.buttons.cancelInvoice'),
        close_button: this.$t('payments.buttons.close'),
        manual_button: this.$t('payments.buttons.paymentReceived'),
        reissue_button: this.$t('payments.buttons.paymentReceived'),
        cancel_success_title: 'Cobrança cancelada com sucesso!',
        send_invoice_button: this.$t('payments.buttons.sendInvoice'),
      },
      block_ui: false,
      block_ui_update: false,
      block_ui_canceled: false,
      block_ui_new_invoice: false,
      block_ui_paid_value: false,
      form_installments: [],
      form_payment_methods: [],
      form_bookings_methods: [],
      form_select_transaction_type: enumType,
      form_select_transaction_kind: transactionKind.filter,
      useDebittModal: false,
      useCreditModal: false,
      useCancelTransactionModal: false,
      useSendInvoiceModal: false,
      useOpenContestation: false,
      useCloseContestation: false,
      error_amount_paid: null,
      interest: 0,
      amount_driver: 0,
      showAmount: false,
      showTimeLineEvents: false,
      transactionEvents: [],
      loadHistoryTransaction: false
    };
  },
  validations: {
    data: {
      amount_paid: {
        required,
        maxLength: maxLength(8),
        minLength: maxLength(1),
      },
    },
  },
  computed: {
    ...mapGetters('user', ['attributes']),
    getKind () {
      return this.data.kind;
    },
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      },
    },
    getCreatedAt: {
      get() {
        let utc = this.$moment.utc(this.data.created_at);

        return this.$moment.tz(utc, this.data.timezone).format("DD/MM/YYYY");
      },
      set(value) {
        this.data.created_at = value;
      },
    },
    getOverdueLimit: {
      get() {
        let utc = this.$moment.utc(this.data.overdue_limit);

        return this.$moment.tz(utc, this.data.timezone).format("DD/MM/YYYY");
      },
      set(value) {
        this.data.overdue_limit = value;
      },
    },
    startPeriodValidity () {
      let utc = this.$moment.utc(this.data.created_at).subtract(7,'d');

      return this.$moment.tz(utc, this.data.timezone).format("DD/MM/YYYY");
    },
    endPeriodValidity () {
      let utc = this.$moment.utc(this.data.created_at);

      return this.$moment.tz(utc, this.data.timezone).format("DD/MM/YYYY");
    },
    getTransactionKind () {
      const currentKind = this.getKind || '';
      const transactionKinds = this.form_select_transaction_kind.length ? this.form_select_transaction_kind : false;

      if (!currentKind || !transactionKinds.length) return ''

      const kind = transactionKinds.filter(kind => kind.value === currentKind)

      return kind[0].text
    },
    finishedAtIsVisible () {
      const enabledKinds = ['INSTALLMENT', 'NEGOTIATION', 'RECURRENCY']

      if (enabledKinds.includes(this.getKind)) return true

      return false
    },
    getPlanId() {
      if (!this.data.booking) return;
      const planData = this.form_bookings_methods.find(item => item.value === this.data.booking);

      return planData?.plan_id || null;
    },
    isBrazil() {
      return (this.$store.getters['user/country'] === 'BR');
    },

    getMethodAvailables() {
      if (!this.data.method_available) return "";

      return this.data.method_available.map((method) => paymentMethodTranslate[method]).join(', ');
    },
  },
  watch: {
    data: {
      deep: true
    }
  },
  mounted() {
    this.getBookings()
  },
  methods: {
    ...mapActions('driver', ['clearHeader', 'header']),
		loadProfile() {
      this.clearHeader();
      this.header({
        id: this.data.driver
      });
		},
    setGateway: formatGateway,
    updateAmountPaid(args) {
      this.$emit('updateAmountPaid', args)
    },
    setPriceFormat (_currency, _value) {
      return formatPrice(_currency, _value);
    },
    handleOpen () {
      this.block_ui = false;
      this.block_ui_update = false;
      this.block_ui_canceled = false;
      this.block_ui_paid_value = true;
      this.data.timezone = this.modal_data.timezone
      this.$v.data.$reset();

      this.block_ui_paid_value = false;
      this.block_ui_new_invoice = true;
      this.data = {
        ...this.modal_data,
        amount_paid: this.modal_data.amount_paid ? this.modal_data.amount_paid : 0,
      };
      this.form_installments = this.modal_data.payment_method_type === 'credit_card' ? [this.modal_data.installment] : [];
      this.btnCreateClicked = false;
      this.modal.save_button = this.$t('payments.buttons.updateInvoice');
      this.modal.save_success_title = this.$t('payments.modal.popup.successUpdate');
      this.modal.refundButton = this.$t('payments.buttons.refund');
      this.modal.title = this.$t('payments.titles.modalTransaction');

      this.block_ui = true;

      if (this.modal_data.status !== 'PAID' && this.modal_data.status !== 'MANUALLY_PAID' && this.modal_data.status !== 'CANCELED') {
        this.block_ui_update = true;
        this.block_ui = false;
      }
      if (this.modal_data.status === 'CANCELED') this.block_ui_canceled = true;

      this.form_select_transaction_kind = transactionKind;

      if (!isNaN(this.data.amount) &&
          !isNaN(this.data.amount_reference) &&
          !isNaN(this.data.discount) &&
          !isNaN(this.data.interest) &&
          !isNaN(this.data.fine) &&
          !isNaN(this.data.days_late) &&
          !isNaN(this.data.transaction_fee) &&
          !isNaN(this.data.taxes_total)) { this.showAmount = true; }
    },
    handleClose () {
      this.block_ui = false;
      this.block_ui_update = false;
      this.currentRouter = this.router.default;
      this.error_amount_paid = null;
      this.showTimeLineEvents = false;
      this.$emit('change', false);
    },
    async getBookings () {
      await makeQuery(
        DRIVER_BOOKINGS,
        { id: this.$route.params.id }
      )
      .then((data) => {
        if (data.loading) return;
        let _items = [];
        data.data.bookingsByDriver.items.forEach(item => {
          if (item.status === 'CANCELED') return;
          const textHasFinished = item.finished_at ? `${this.$moment(item.started_at)} a ${this.$moment(item.finished_at)}` : 'Ativo';

          if (textHasFinished === 'Ativo') this.data.booking = item.id;

          _items.push({
            value: item.id,
            plan_id: item.plan.id,
            text: `${item.plan.name} - ${textHasFinished}`,
            kind_configurations: item.plan.kind_configurations,
            status: item.status
          });
        });
        this.form_bookings_methods = _items;
      })
    },
    getPlanActive () {
      if (this.form_bookings_methods.length > 0) {
        const planData = this.form_bookings_methods.find(item => item.status === "ACTIVE");
        return planData ? planData.value : ''
      }

      return ''
    },
    showModal (type) {
      let showModalObject = {
        type: 'success',
        timer: 2500,
        showConfirmButton: false
      }

      if (type === 'errorDefault' || type === 'errorSendInvoice') showModalObject.type = 'error'

      switch (type) {
        case 'errorDefault':
          showModalObject.title = this.$t('payments.modal.popup.error.title')
          showModalObject.text = this.$t('payments.modal.popup.error.text')
          break
        case 'successProofPaymentApproved':
          showModalObject.title = this.$t('payments.modal.popup.approved.success.title')
          break
        case 'successProofPaymentRejected':
          showModalObject.title = this.$t('payments.modal.popup.approved.error.title')
          break
        case 'successSendInvoice':
          showModalObject.title = this.$t('payments.modal.popup.success.title')
          break
        case 'errorSendInvoice':
          showModalObject.title = this.$t('payments.modal.popup.error.title')
          showModalObject.text = this.$t('payments.modal.popup.error.text')
          break
        default:
          showModalObject.title = this.modal.save_success_title
          break
      }

      this.block_ui = false;

      if (type === 'errorDefault' || type === 'errorSendInvoice') {
        this.$swal(showModalObject)
          .then(() => this.$emit('refresh'))
      } else {
        this.$emit('change', false);

        this.$swal(showModalObject)
          .then(() => this.$emit('refresh'))
      }
    },
    openModalName (modalName) {
      if (modalName === 'cancelTransaction') this.useCancelTransactionModal = true

      if (modalName === 'markAsBonus' || modalName === 'reIssueBoleto') {
        const original_data = this.data
        let swalConfig = {
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger mr-3',
          buttonsStyling: false,
        }
        let configConfirmationWindow = {
          title: 'Tem certeza?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        }

        switch (modalName) {
          case 'markAsBonus':
            configConfirmationWindow.text = this.$t('payments.modal.popup.bonus.text')
            break
          case 'reIssueBoleto':
            configConfirmationWindow.text = this.$t('payments.modal.popup.boleto.text')
            break
        }

        const confirmationWindow = this.$swal.mixin(swalConfig)

        confirmationWindow(configConfirmationWindow)
          .then(result => {
            if (result.value) {
              // check all params
              const validateParams = true

              if (modalName === 'markAsBonus' && !this.data.id || !this.data.driver || !this.data.invoice || !this.data.payment_method_token) validateParams = false
              if (modalName === 'reIssueBoleto' && !this.data.id || !this.data.barcode_number) validateParams = false

              // if some value empty or false, show modal error
              if (!validateParams) return this.showModal('errorDefault');

              let mutationObject = {
                mutation: REISSUE_BOLETO,
                variables: {
                  id: this.data.id,
                }
              }

              if (modalName === 'markAsBonus') {
                mutationObject.mutation = MARK_AS_BONUS
                mutationObject.variables.driver = this.data.driver
                mutationObject.variables.invoice = this.data.invoice
                mutationObject.variables.payment_method_token = this.data.payment_method_token
              }

              this.$apollo
                .mutate(mutationObject)
                .then(() => this.showModal('successDefault'))
                .catch(() => {
                  this.data = original_data;
                  this.showModal('errorDefault');
                });
            } else {
              this.block_ui = false;
              this.block_ui_update = false;
            }
          })
      }

      if (modalName === 'showRefundConfirm') this.modalRefund = true

      if (modalName === 'debitBalance') this.useDebittModal = true

      if (modalName === 'creditBalance') this.useCreditModal = true

      if (modalName === 'sendInvoice') this.useSendInvoiceModal = true

      if (modalName === 'openContestation') this.useOpenContestation = true

      if (modalName === 'closeContestation')  this.useCloseContestation = true

      if (modalName === 'handleSubmit') {
        if (this.data.id) return this.doUpdateTransaction()

        this.doCreateTransaction()
      }
    },
    closeModalName (modalName) {
      switch (modalName) {
        case 'refund':
          this.modalRefund = false
          break
        case 'debitBalance':
          this.useDebittModal = false
          break
        case 'creditBalance':
          this.useCreditModal = false
          break
        case 'cancelTransaction':
          this.useCancelTransactionModal = false
          break
        case 'sendInvoice':
          this.useSendInvoiceModal = false
          break
        case 'openContestation':
          this.useOpenContestation = false
          break
        case 'closeContestation':
          this.useCloseContestation = false
          break
      }
    },

    // Sem definicao ainda de onde foi parar a atualziacao, entao mantendo o codigo para futuros updates
    doUpdateTransaction() {
      const original_data = this.data;
      this.block_ui = true;
      this.block_ui_update = false;
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });
      confirmationWindow({
        title: 'Tem certeza?',
        text: 'Você irá mudar a cobrança deste motorista',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then(result => {
        if (!result.value) return (this.block_ui = false);
        this.$apollo
          .mutate({
            mutation: TRANSACTION_UPDATE,
            variables: {
              id: this.data.id,
              driver: this.data.driver,
              booking: this.data.booking,
              invoice: this.data.invoice,
              payment_method: this.data.payment_method,
              internal_comments: this.data.internal_comments,
              type: this.data.type,
              kind: this.data.kind,
              description: this.data.description,
              payment_method_token: this.data.payment_method_token
                ? this.data.payment_method_token
                : find(this.payment_method.items, {
                    id: this.data.payment_method,
                  }).token,
              payment_method_type: this.data.payment_method_type
                ? this.data.payment_method_type
                : find(this.payment_method.items, {
                    id: this.data.payment_method,
                  }).type,
              amount: parseInt(this.data.amount),
              discount: parseInt(this.data.discount),
              installment: parseInt(this.data.installment) || 1,
            },
          })
          .then(() => this.showModal('successDefault'))
          .catch(() => {
            this.data = original_data;
            this.showModal('errorDefault');
          });
      });
    },
    checkPaymentAttempt () {
      makeMutation(
        CHECK_PAYMENT_ATTEMPT,
        {
          invoice: this.data.invoice,
        },
      )
        .then(() => {
          this.showModal('successDefault');
        })
        .catch((error) => {
          this.showModal('errorDefault');
        })
    },
    getTransactionEvents () {
      if (!this.showTimeLineEvents) {
        this.loadHistoryTransaction = true
        makeQuery(
          GET_TRANSACTION_EVENTS,
          {
            id: this.data.id,
          }
        )
        .then((res) => {
          this.transactionEvents = res.data.getTransactionEvents
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.showTimeLineEvents = true
          this.loadHistoryTransaction = false
        })
      } else {
        this.showTimeLineEvents = false
      }
    },
    getPaymentMethod () {
      const type = this.modal_data.payment_method_type
      const cc_brand = this.modal_data.payment_method_cc_brand
      const cc_number = this.modal_data.payment_method_cc_number
      if (type === 'credit_card' && cc_brand && cc_number){
        return `${this.$t(`recurrency.labels.${type}`)} - ${cc_brand} - ${cc_number} `
      }
      return `${this.$t(`recurrency.labels.${type}`)}`
    },
    confirmationModal (
      $title = this.$t('drivers.texts.areYouSure'),
      $text = this.$t('drivers.texts.confirmsTheAction'),
      $inputPlaceholder = this.$t('drivers.texts.whatReason'),
    ) {
      return new Promise((resolve, reject) => {
        const confirmationWindow = this.$swal.mixin({
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger mr-3',
          buttonsStyling: false,
        });
        confirmationWindow({
          title: $title,
          text: $text,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('drivers.buttons.confirm'),
          cancelButtonText: this.$t('drivers.buttons.cancel'),
          reverseButtons: true,
          input: 'text',
          inputValue: '',
          inputPlaceholder: $inputPlaceholder,
          inputValidator: value => !value && this.$t('drivers.texts.mandatoryReason'),
          inputAttributes: {
            maxlength: 250,
            autocomplete: 'off',
          },
        }).then(async result => {
          if (!result || result.dismiss === 'cancel') reject();

          resolve(result);
        }).catch(() => {
          reject();
        })
      })
    },
    convertPrePaymentInCredits (transaction) {
      this.confirmationModal(
        this.$t('drivers.texts.areYouSure'),
        this.$t('drivers.texts.confirmsConvertInCredits'),
        this.$t('drivers.texts.whatReason'),
      )
        .then((result) => {
          makeMutation(
            CREDIT_BALANCE,
              {
                transaction_id: transaction.id,
                invoice_id: transaction.invoice,
                value: transaction.amount_paid,
                blame: this.attributes.email,
                kind: transaction.kind,
                internal_comments: result.value
              }
            )
            .then(() => {
              this.$emit('refresh')
              this.showModal('successDefault')
              this.handleClose()
            })
            .catch(() => {
              this.showModal('errorDefault')
            })
        })
    },
    payWithPrePayment (transaction) {
      this.confirmationModal(
        this.$t('drivers.texts.areYouSure'),
        this.$t('drivers.texts.payWithPrePayment')
      )
        .then((result) => {
          makeMutation(
            DEBIT_BALANCE,
              {
                transaction_id: transaction.id,
                invoice_id: transaction.invoice,
                value: transaction.amount,
                blame: this.attributes.email,
                kind: 'PRE_PAYMENT',
                internal_comments: result.value
              }
            )
            .then(() => {
              this.loadProfile()
              this.$emit('refresh')
              this.showModal('successDefault')
              this.handleClose()
            })
            .catch(() => {
              this.showModal('errorDefault')
            })
        })
    },
    copyInvoice () {
      const clipboard = new ClipboardJS('#clip-invoice',
        {
          text: () => this.data.invoice
        }
      );

      clipboard.on('success', (e) => {
        this.$snotify.success(this.$t('payments.labels.clipboardSuccess'));
        e.clearSelection();
        clipboard.destroy();
      });

      clipboard.on('error', () => {
        this.$snotify.error(this.$t('payments.labels.clipboardError'));
        clipboard.destroy();
      });
    },
    getContestationTitle(contestation) {
      if (contestation?.status == "OPEN" && contestation?.opening_comments) return contestation?.opening_comments
      if (contestation?.status == "FINISHED" && contestation?.closing_comments) return contestation?.closing_comments
      return this.$t('payments.labels.contestationReasonFallback')
    }
  }
};
</script>

<style scoped>
.lines {
  padding-bottom: 30px;
}
.titles {
  margin-bottom: 8px;
  font-size: 14px;
  color: #000000;
}
.texts {
  margin-bottom: 0;
  font-size: 14px;
  color: #000000;
}
.kind {
  color: #25AAD6;
}
.comments {
  background-color: #EAEDF3 !important;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 76px;
}
</style>
